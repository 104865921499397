import { Box, BoxProps, Text } from '@chakra-ui/react';
import { useSession } from 'next-auth/react';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { FormattedPrice } from '@/components/FormattedPrice/FormattedPrice';

import { Product } from '@/models/api';
import { AppService } from '@/services';

export type PriceUnitDetails = Pick<
    Product,
    | 'netPrice'
    | 'netUnitPrice'
    | 'unit'
    | 'currency'
    | 'productAvailableForSale'
    | 'netPromoPrice'
    | 'grossPrice'
> & {
    boxStyleProps?: BoxProps;
};

export const ProductUnitPrice = (props: PriceUnitDetails) => {
    const {
        grossPrice,
        netPrice,
        netPromoPrice,
        netUnitPrice,
        unit,
        productAvailableForSale,
        boxStyleProps
    } = props;
    const currency = AppService.getInstanceCurrency();
    const { data } = useSession();

    const hasUnit = useMemo(() => !!unit?.trim(), [unit]);

    const hasPrice = useMemo(
        () => netUnitPrice && parseFloat(netUnitPrice.replace(',', '.')) > 0,
        [netUnitPrice]
    );

    return (
        <>
            {productAvailableForSale && grossPrice && (
                <Box pt={3} {...boxStyleProps}>
                    <Text fontSize="sm" color="grey.700">
                        <FormattedPrice
                            value={data ? netPrice?.toString() : netPromoPrice}
                            currency={currency}
                        />{' '}
                        <FormattedMessage id="netto" />{' '}
                        {hasPrice && hasUnit && (
                            <>
                                &#124; <FormattedPrice value={netUnitPrice} currency={currency} />{' '}
                                <FormattedMessage id="for" /> {unit}
                            </>
                        )}
                    </Text>
                </Box>
            )}
        </>
    );
};
