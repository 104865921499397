import { AttachmentIcon } from '@chakra-ui/icons';
import { Box, List, ListItem } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { H4 } from '@/components/Heading';
import NavLink from '@/components/UI/Links/NavLink';

import { ProductFiles } from '@/models/props/ProductCardProps';
import { StrapiUtilsService } from '@/services/StrapiUtilsService';

type ProductAttachmentsProps = {
    files: ProductFiles[] | undefined;
};

export const ProductAttachments = ({ files }: ProductAttachmentsProps) => {
    const listItem = files?.map((item, index) => {
        const { attributes } = item;
        const { name } = attributes;
        const url = StrapiUtilsService.updateUploadsUrl(attributes.url);
        return (
            <ListItem key={index}>
                <NavLink
                    href={url}
                    download={name}
                    isExternal
                    display="inline-flex"
                    alignItems="center"
                >
                    <AttachmentIcon mr={2} /> {name}
                </NavLink>
            </ListItem>
        );
    });

    if (!files?.length) {
        return <></>;
    }

    return (
        <Box>
            <H4 fontSize="h5">
                <FormattedMessage id="attachments" />
            </H4>
            <List>{listItem}</List>
        </Box>
    );
};
