import { Box } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

type ProductPointsProps = {
    points: number;
};

export const ProductPoints: FunctionComponent<ProductPointsProps> = ({ points }) => {
    if (isNaN(+points) || !points) {
        return <></>;
    }

    return (
        <Box
            borderRadius={26}
            px={3}
            py={1}
            flexShrink={0}
            fontWeight="bold"
            backgroundColor="grey.100"
        >
            <FormattedMessage
                id="points"
                values={{
                    count: points
                }}
            />
        </Box>
    );
};
