import { Box, Text, TextProps } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

export interface ProductCodeProps {
    productId: string | number;
    fontSize?: string;
    color?: string;
    styleProps?: TextProps;
}

export const ProductCode = (props: ProductCodeProps) => {
    const { productId, fontSize, color, styleProps } = props;
    return (
        <Text fontSize={fontSize || 'sm'} color={color || 'grey.700'} {...styleProps}>
            <FormattedMessage id="product-code" />
            &#58; <Box as="span">{productId}</Box>
        </Text>
    );
};
