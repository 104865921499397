import { Flex, Text } from '@chakra-ui/react';
import { useShoppingCart } from 'context/shoppingCartContext';
import { FormattedMessage } from 'react-intl';

import { FormattedPrice } from '@/components/FormattedPrice/FormattedPrice';
import { ProductUVPPrice } from '@/components/Product/ProductUVPPrice/ProductUVPPrice';

import { Product } from '@/models/api';
import { AppService } from '@/services';

export type ProductPriceProps = Pick<
    Product,
    | 'grossPrice'
    | 'grossPriceBeforeDiscount'
    | 'currency'
    | 'productAvailableForSale'
    | 'discount'
    | 'code'
    | 'new'
    | 'notAvailableMessage'
>;

export const ProductPrice = (props: ProductPriceProps) => {
    const { getItemQuantity } = useShoppingCart();
    const {
        grossPrice,
        grossPriceBeforeDiscount,
        new: isNew,
        productAvailableForSale,
        discount,
        notAvailableMessage
    } = props;
    const currency = AppService.getInstanceCurrency();

    return (
        <>
            {(productAvailableForSale && grossPrice) ||
            (!productAvailableForSale && getItemQuantity(props.code)) ? (
                <Flex
                    mt={3}
                    alignItems="flex-end"
                    columnGap={3}
                    lineHeight="1"
                    flexWrap="wrap"
                    rowGap={2}
                >
                    <Text fontSize="27px" fontWeight="bold" whiteSpace="nowrap">
                        <FormattedPrice value={grossPrice} currency={currency} />
                    </Text>
                    {discount !== undefined && discount < 0 && (
                        <>
                            <Text
                                color="blue.main"
                                fontSize="md"
                                fontWeight="bold"
                                bgColor="blue.50"
                                borderRadius="16px"
                                p={2}
                                pos="relative"
                                bottom="-5px"
                                className="test123"
                                pointerEvents="none"
                            >
                                {discount.toString()}&#37;
                            </Text>
                        </>
                    )}
                    <ProductUVPPrice
                        grossPriceBeforeDiscount={grossPriceBeforeDiscount}
                        discount={discount}
                        isNew={isNew}
                        currency={currency}
                    />
                </Flex>
            ) : (
                <Text fontSize="xl" color="grey.600">
                    {notAvailableMessage ? (
                        notAvailableMessage
                    ) : (
                        <FormattedMessage
                            id={
                                grossPrice !== null
                                    ? 'product.unavailable'
                                    : 'product.not-available-in-catalog'
                            }
                        />
                    )}
                </Text>
            )}
        </>
    );
};
