import { Box, Text } from '@chakra-ui/react';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { FormattedPrice } from '@/components/FormattedPrice/FormattedPrice';

import { AppService } from '@/services';

type ProductUVPPriceProps = {
    grossPriceBeforeDiscount?: string;
    discount?: number;
    isNew?: boolean;
    currency?: string;
};

export const ProductUVPPrice = (props: ProductUVPPriceProps) => {
    const { grossPriceBeforeDiscount, isNew, discount } = props;

    const currency = AppService.getInstanceCurrency();

    const hasDiscount = useMemo(() => !!discount && discount < 0, [discount]);

    const uvpPriceVisible = AppService.isGermanInstance() && isNew;

    return (
        <>
            {hasDiscount && (
                <Text color="gray">
                    {uvpPriceVisible && (
                        <Box as="span">
                            <FormattedMessage id="uvp" />{' '}
                        </Box>
                    )}
                    <Box as="span" textDecoration="line-through">
                        <FormattedPrice value={grossPriceBeforeDiscount} currency={currency} />
                    </Box>
                </Text>
            )}
        </>
    );
};
